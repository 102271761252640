<template>
    <div>
        <b-overlay :show="!dataLoaded">
            <b-card :title="reservation.name">
                <b-row>
                    <b-col>
                        <label>New day</label>
                        <flat-pickr
                            v-model="newDayDate"
                            class="form-control"
                            :config="{ enableTime: false, dateFormat: 'Y-m-d H:i'}"
                        />
                    </b-col>
                    <b-col>
                        <b-button variant="primary" @click="addDay" class="mt-2">
                            <feather-icon
                                icon="PlusIcon"
                            />
                            <span class="align-middle">Add day</span>
                        </b-button>
                    </b-col>
                </b-row>
                <app-collapse accordion class="mt-2" type="margin">
                    <app-collapse-item v-for="day in days" :key="day.date" :title="day.date">
                        <table>
                            <tr :key="day.date + time.time" v-for="time in day.times">
                                <th class="pb-1">
                                    <span class="font-weight-bold mr-2">{{time.time}}</span>
                                </th>
                                <td class="pb-1" v-if="time.user.length > 0">
                                    {{time.user}}
                                </td>
                                <td class="pb-1" v-else>
                                    <b-badge variant="light-success">Available</b-badge>
                                </td>
                            </tr>
                        </table>

                        <b-button variant="danger" @click="removeDay(day.id)" class="mt-2">
                            <feather-icon
                                icon="Trash2Icon"
                            />
                            <span class="align-middle">Delete</span>
                        </b-button>
                    </app-collapse-item>
                </app-collapse>
            </b-card>
        </b-overlay>
    </div>
</template>
<script>

    import {BButton, BCard, BOverlay, BRow, BCol, BBadge} from 'bootstrap-vue'
    import flatPickr from 'vue-flatpickr-component'
    import AppCollapse from '@core/components/app-collapse/AppCollapse'
    import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem'

    export default {
        components: {
            AppCollapseItem,
            AppCollapse,
            BRow,
            BCol,
            BOverlay,
            BCard,
            BButton,
            BBadge,
            flatPickr
        },
        props: {
            reservation: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                dataLoaded: false,
                days: [],

                newDayDate: null
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                if (this.reservation.id.length === 0) {
                    this.days = []
                    this.dataLoaded = true
                    return
                }
                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/fake_erp/reservations/${  this.reservation.id  }/days`)
                loadPromise.then(function(response) {
                    thisIns.days = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addDay() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.post(`/api/management/v1/fake_erp/reservations/${  this.reservation.id  }/days`, {
                    date: this.moment(this.newDayDate).format()
                })
                loadPromise.then(function() {
                    thisIns.newDayDate = null
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            removeDay(id) {
                const thisIns = this
                const loadPromise = this.$http.delete(`/api/management/v1/fake_erp/reservations/${  this.reservation.id  }/days/${  id}`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Day removed')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            }
        },
        watch: {
            reservation() {
                this.loadData()
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>