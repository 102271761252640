<template>
    <div>
        <b-row>
            <b-col>
                <b-overlay :show="!dataLoaded">
                    <b-card>

                        <b-button variant="primary" @click="addModalActive = true">
                            <feather-icon
                                icon="PlusIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Add</span>
                        </b-button>

                        <basic-table :columns="columns" :data="reservations" v-slot="props">
                            <div v-if="props.column.displayType === 2" class="d-flex justify-content-center">
                                <b-button variant="success" @click="selectedReservation = props.row" class="mr-1">
                                    <feather-icon
                                        icon="EyeIcon"
                                    />
                                </b-button>
                                <b-button variant="danger" @click="removeReservation(props.row.id)">
                                    <feather-icon
                                        icon="Trash2Icon"
                                    />
                                </b-button>
                            </div>
                            <span v-else-if="props.column.displayType === 1" class="d-flex justify-content-center">
                                {{ (Number(props.formattedRow[props.column.field]) / 100).toFixed(2) }}
                            </span>
                            <span v-else class="d-flex justify-content-center">
                                {{ props.formattedRow[props.column.field] }}
                            </span>

                        </basic-table>
                    </b-card>
                </b-overlay>
            </b-col>
            <b-col v-if="selectedReservation">
                <DaysList :reservation="selectedReservation"/>
            </b-col>
        </b-row>


        <b-modal title="Add new" v-model="addModalActive" no-close-on-backdrop>
            <template #default>
                <b-form-group>
                    <label>Name:</label>
                    <b-form-input
                        type="text"
                        placeholder="Name"
                        v-model="addObject.name"
                    />
                </b-form-group>

                <b-form-group>
                    <label>Price:</label>
                    <b-form-input
                        type="number"
                        placeholder="Price"
                        v-model="addObject.price"
                    />
                </b-form-group>
            </template>

            <template #modal-footer>

                <b-button variant="danger" @click="addModalActive = false">
                    <feather-icon
                        icon="XIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Close</span>
                </b-button>

                <b-button variant="primary" @click="addReservation">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Add</span>
                </b-button>
            </template>
        </b-modal>

    </div>
</template>
<script>

    import BasicTable from '@/views/components/BasicTable'
    import {BButton, BCard, BCol, BFormGroup, BFormInput, BModal, BOverlay, BRow} from 'bootstrap-vue'
    import DaysList from '@/views/FakeERP/Reservations/DaysList'

    export default {
        components: {
            BRow,
            BCol,
            DaysList,
            BasicTable,
            BOverlay,
            BCard,
            BButton,
            BModal,
            BFormInput,
            BFormGroup
        },
        data() {
            return {

                reservations: [],
                dataLoaded: false,

                selectedReservation: null,

                columns: [
                    {
                        label: 'ID',
                        displayType: 0,
                        field: 'id',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search ID'
                        }
                    },
                    {
                        label: 'Name',
                        displayType: 0,
                        field: 'name',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Name'
                        }
                    },
                    {
                        label: 'Price',
                        displayType: 1,
                        field: 'price',
                        filterOptions: {
                            enabled: true,
                            placeholder: 'Search Price'
                        }
                    },
                    {
                        label: 'Actions',
                        displayType: 2,
                        field: 'actions',
                        filterOptions: {
                            enabled: false
                        }
                    }
                ],

                addObject: {},
                addModalActive: false

            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get('/api/management/v1/fake_erp/reservations')
                loadPromise.then(function(response) {
                    thisIns.reservations = response.data
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })

                Promise.all([loadPromise]).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            addReservation() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.post('/api/management/v1/fake_erp/reservations', {
                    name: this.addObject.name,
                    price: Number((Number(this.addObject.price) * 100).toFixed(0))
                })
                loadPromise.then(function() {
                    thisIns.$printSuccess('Reservation added')
                    thisIns.addModalActive = false
                    thisIns.$set(thisIns.addObject, 'name', '')
                    thisIns.$set(thisIns.addObject, 'price', null)
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            },
            removeReservation(id) {
                const thisIns = this
                const loadPromise = this.$http.delete(`/api/management/v1/fake_erp/reservations/${  id}`)
                loadPromise.then(function() {
                    thisIns.$printSuccess('Reservation removed')
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.loadData()
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }

</script>